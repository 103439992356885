import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";
import MaidanCard from '../Components/MaidanCard';


export default function Maidan(props) {
    const navigate = useNavigate() ;

    useEffect(()=>{
        document.title = 'Bailgada Live Maidan | मैदान - बैलगाडा Live';
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "all maidan page title" });
        window.scrollTo(0, 0);

    },[])

    const handle = (name , id)=>{
        ReactGA.event({
            category : "MAIDAN ALL CLICK CATEGORY" ,
            action : "SEPCIFIC MAIDAN CLICKED ON ALL MAIDAN PAGE" ,
            label : "MAIDAN CICCK EVENT" ,
            value :id ,
          }) ;
        const formattedName = name.replace(/\s+/g, '-'); 
        navigate(`/maidan/${formattedName}/${id}`) ;
      }

  return (
    <div className='pt-16 lg:px-96 bg-stone-100'>
      <div>
      <Link to="/maidaninput"> <h1 className='bg-black text-white py-1 border-black m-2 border-2 rounded-lg  text-center active:scale-90 transition-transform cursor-pointer'>मैदानाची नोंद करा</h1></Link>
        </div>
        <div className='px-0.5'>
        <h1 className='bg-black text-white mt-2 p-2 rounded' >आगामी मैदाने </h1>
        <div className="flex flex-wrap    justify-between bg-stone-100 rounded p-1 text-center z-0 mx-4 lg:mx-0">
            {props.arr2.map((ele, index) => {
              const date = new Date(ele.date);
              const formattedDate = date
                .toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
                .split("/")
                .join("/");

              return (
                <MaidanCard name={ele.name} _id={ele._id} image={ele.image[0]} handle={handle} fee={ele.fee} village={ele.address.village} taluka={ele.address.taluka} />
              );
            })}
          </div>
        </div>
        <div className='px-0.5'>
        <h1 className='bg-black text-white mt-2 p-2 rounded' >मागील मैदाने</h1> 
        <div className="flex flex-wrap    justify-between bg-stone-100 rounded p-1 text-center z-0 mx-4 lg:mx-0">
            {props.arr1.map((ele, index) => {
              const date = new Date(ele.date);
              const formattedDate = date
                .toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
                .split("/")
                .join("/");

              return (
                <MaidanCard name={ele.name} _id={ele._id} image={ele.image[0]} handle={handle} fee={ele.fee} village={ele.address.village} taluka={ele.address.taluka} />
              );
            })}
          </div>
        </div>
    </div>
  )
}
