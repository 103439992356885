import React, { useState, useEffect } from "react";
import { Route, Routes, Link } from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga4";
import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Bail from "./Page/Bail";
import Bakasur from "./Page/Bakasur";
import Home from "./Page/Home";
import Contact from "./Page/Contact";
import About from "./Page/About";
import Maidaninput from "./Page/Maidaninput";
import Bailinput from "./Page/Bailinput";
import Maidan from "./Page/Maidan";
import MaidanItem from "./Page/MaidanItem";
// import Driver from "./Page/Driver";
// import Malak from "./Page/Malak";
// import Malakitem from "./Page/Malakitem";
// import Driveritem from "./Page/Driveritem";
import Error from "./Page/Error";
import Preloader from "./Components/Preloader";
import Home_icon from "./Images/icons8-home-48.png";
import Bull_Icon from "./Images/logo2.png";
import Race_Icon from "./Images/finish.png";
import Mail_Icon from "./Images/mail.png";
import Aboutus_Icon from "./Images/information-button.png";
import Menu_Icon from "./Images/categories.png"

export default function App() {
  ReactGA.initialize(process.env.REACT_APP_GA4_ID);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    function disablePreloader() {
      const loader = document.getElementsByClassName("preloader")[0];
      loader.style.display = "none";
    }
    setTimeout(() => {
      disablePreloader();
    }, 5000);
    getnikal();
    getbail();
    getbail1();
    getbail2();
    getbail3();
    getnews();
  }, []);

  const [arr, setarr] = useState([]);
  const [arr1, setarr1] = useState([]);
  const [arr2, setarr2] = useState([]);
  const [arr3, setarr3] = useState([]);
  const [mname, setmname] = useState("");
  const [mid, setmid] = useState("");
  const [news, setnews] = useState([]);

  const getnews = async () => {
    try {
      const data1 = await axios.get(
        `${baseUrl}/api/news/get`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setnews(data1.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getnikal = async () => {
    try {
      const data1 = await axios.get(
        `${baseUrl}/api/maidan/nikal`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setmname(data1.data.mname);
      setmid(data1.data.mid);
    } catch (error) {
      console.log(error);
    }
  };

  const getbail = async () => {
    try {
      const data1 = await axios.get(
        `${baseUrl}/api/bail/getall`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setarr(data1.data);
    } catch (error) {
      toast.error("Internal Server Error");
    }
  };

  const getbail1 = async () => {
    try {
      const data1 = await axios.get(
        `${baseUrl}/api/maidan/previous/getall`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setarr1(data1.data);
    } catch (error) {
      toast.error("Internal Server Error");
    }
  };

  const getbail2 = async () => {
    try {
      const data1 = await axios.get(
        `${baseUrl}/api/maidan/after/getall`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setarr2(data1.data);
    } catch (error) {
      toast.error("Internal Server Error");
    }
  };

  const getbail3 = async () => {
    try {
      const data1 = await axios.get(
        `${baseUrl}/api/maidan/current/getall`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setarr3(data1.data);
    } catch (error) {
      toast.error("Internal Server Error");
    }
  };

  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const closeSidebar = () => {
    setShowSidebar(false);
  };

  return (
    <div>
      <Preloader />
      <Header
        setShowSidebar={setShowSidebar}
        showSidebar={showSidebar}
        closeSidebar={closeSidebar}
      />

      <div className=" hidden lg:block  fixed  w-60 h-screen bg-stone-50  border-e border-neutral-300   ">
        <Link to="/">
          <div className="p-3 inline-flex">
            <img className="w-10" src={Bull_Icon} alt="" />
            <h1 className="my-auto ms-2 font-extrabold  text-2xl text-black">
              बैलगाडा <span className="t">Live</span>
            </h1>
          </div>
        </Link>
        <div className={` text-black font-bold   `}>
          <ul className="ps-4 text  ">
            <li className=" border-b border-slate-200 py-6 z-30">
              <Link to="/" className=" flex ">
                <img className="me-2" src={Home_icon} width={"20px"} alt="" />
                मुखपृष्ट
              </Link>
            </li>
            <li className=" border-b border-slate-200 py-6">
              <Link to="/bail" className="flex">
                <img className="me-2" src={Bull_Icon} width={"20px"} alt="" />
                आपली बैल
              </Link>
            </li>
            <li className=" border-b border-slate-200 py-6 ">
              <Link to="/maidan" className="flex">
                <img className="me-2" src={Race_Icon} width={"20px"} alt="" />
                मैदाने
              </Link>
            </li>
            <li className=" border-b border-slate-200 py-6 ">
              <Link to="/contact" className="flex">
                {" "}
                <img className="me-2" src={Mail_Icon} width={"20px"} alt="" />
                संपर्क
              </Link>
            </li>
            <li className=" border-b border-slate-200 py-6">
              <Link to="/about" className="flex">
                <img
                  className="me-2"
                  src={Aboutus_Icon}
                  width={"20px"}
                  alt=""
                />
                आमच्यविषयी माहिती
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="block lg:hidden fixed w-screen h-14 bottom-0  bg-stone-50 border-t border-neutral-300   z-40   ">
        <div className={` text-black font-bold   `}>
          <ul className="ps-4 text flex flex-row justify-around items-center align-middle   ">
            <li className="my-4">
              <Link to="/" className=" flex ">
                <img className="me-2" src={Home_icon} width={"30px"} alt="" />
              </Link>
            </li>
            <li className="  ">
              <Link to="/bail" className="flex">
                <img className="me-2" src={Bull_Icon} width={"30px"} alt="" />
              </Link>
            </li>
            <li className="   ">
              <Link to="/maidan" className="flex">
                <img className="me-2" src={Race_Icon} width={"30px"} alt="" />
              </Link>
            </li>
            <li className="" onClick={toggleSidebar}>
                <img className="me-2" src={Menu_Icon} width={"30px"} alt="" />

            </li>
            {/* <li className="  ">
              <Link to="/about" className="flex">
                <img
                  className="me-2"
                  src={Aboutus_Icon}
                  width={"20px"}
                  alt=""
                />
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              news={news}
              arr3={arr3}
              mname={mname}
              mid={mid}
              arr={arr}
              arr1={arr1}
              arr2={arr2}
            />
          }
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/Maidan" element={<Maidan arr1={arr1} arr2={arr2} />} />
        <Route path="/Maidan/:name/:id" element={<MaidanItem arr1={arr1} />} />
        <Route path="/maidaninput" element={<Maidaninput />} />
        <Route path="/bail" element={<Bail arr={arr} />} />
        <Route path="/bail/:name/:id" element={<Bakasur />} />
        <Route path="/bailinput" element={<Bailinput />} />
        {/* <Route path="/malak" element={<Malak />} /> */}
        {/* <Route path="/malak/:name/:id" element={<Malakitem />} /> */}
        {/* <Route path="/driver" element={<Driver />} /> */}
        {/* <Route path="/driver/:name/:id" element={<Driveritem />} /> */}
        <Route path="/*" element={<Error />} />
      </Routes>
      <Footer />
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}
