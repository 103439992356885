import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

export default function Bail(props) {
  useEffect(() => {
    document.title =
      "Bailgada Live - Bail Information | बैलगाडा Live - आपली बैल";

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "All bail page Title",
    });
  }, []);

  return (
    <div className=" pt-16 lg:pt-4 bg-stone-100 lg:px-96">
      <div className="px-2">
        <Link to="/bailinput">
          <h4 className="bg-black text-white py-1 border-black m-2 border-2 rounded-lg  text-center active:scale-90 transition-transform cursor-pointer">
            आपल्या बैलाची नोंदणी करा
          </h4>
        </Link>
      </div>
      <h1 className="bg-black text-white mt-2 mb-2 py-2 text-lg px-4 rounded mx-0.5">
        आपली लाडकी बैल
      </h1>

      <div className="flex flex-wrap justify-around ">
        {props.arr.map((ele, index) => {
          return (
            <div
              key={index}
              className="text-white bg-white mx-4 my-5 lg:w-80  border rounded border-neutral-400  lg:mx-0"
            >
              <Link to={`/bail/${ele.name}/${ele._id}`}>
                <img className="rounded" src={ele.image[0]} alt="" />
              </Link>
              <div className="text-center text-black pb-2 pt-1">
                <h1 className="">{ele.name}</h1>
                <p className=" text-sm ">
                  {ele.address.village}, {ele.address.district}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
