import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "../Components/Spinner";
import ReactGA from "react-ga4";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import {toast } from 'react-toastify';
import WrongInformation from "../Components/WrongInformation";
import { BiSolidPhoneCall } from "react-icons/bi";
import addsym from "../Images/location.png"



export default function Bakasur() {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const params = useParams();
  const [ele, setitem] = useState({});
  const [flag, setflag] = useState(false);
  useEffect(() => {
    document.title = `${params.name} Bailgada Live | बैलगाडा Live`;

    window.scrollTo(0, 0);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Specific Bakasur Bail View",
    });

    getbail();

  }, []);
  const [isPreview, setPreview] = useState(false);
  const [preViewImage, setpreViewImage] = useState(null);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const handleShowDialog = (e) => {
    setPreview(!isPreview);
    console.log("cliked");
  };

  const getbail = async () => {
    try{
    const data1 = await axios.get(
      `${baseUrl}/api/bail/get/${params.id}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setitem(data1.data);
    setflag(true);
    }catch(error)
    {
      toast.error('Failed To Submit. Try Again');
    }
  };

  console.log(ele);
  return (
    <div className="pt-20  w-screen bg-stone-100">
      {flag ? (
        <div className=" lg:px-96 border rounded border-black mx-0.5 mb-1">
          <Modal
            isOpen={isPreview}
            onRequestClose={handleShowDialog}
            style={customStyles}
          >
            <div className="relative py-2">
              <button
                className="text text absolute z-50 right-0 -top-3 text-2xl"
                onClick={handleShowDialog}
              >
                <IoMdClose />
              </button>
            </div>
            <div className=" ">
              <img
                className="image w-screen "
                src={preViewImage}
                alt="no image"
              />
            </div>
          </Modal>
          <div className="">
            <img
              onClick={() => {
                setpreViewImage(ele.image[0]);
                handleShowDialog();
              }}
              className=" w-full rounded   lg:h-1/2 lg:w-96 lg:mx-auto lg:my-2"
              src={ele.image[0]}
              alt=""
            />
          </div>

          <div className=" ">
            <h1 className=" text-center font-bold text-5xl underline my-2 font-custom1">
              {ele.name}
            </h1>
            <div className=" bg-gray-100 p-2 mx-1 rounded flex flex-col gap-3">
             {ele.tempname && <div>
                <h1 className="inline">
                  बैलाचे टोपण नाव :- <span>{ele.tempname}</span>
                </h1>
              </div>}
              <h1 className="">गाडीचे नाव :- {ele.gadi_name}</h1>
{         ( ele.type) &&    <h1 className="">बैलाची जात :- {ele.type}</h1>
}
              <span>
              <h1 className="">मालकाचे नाव </h1>
                {ele.malak.map((ele) => {
                  return (
                    <div className="ms-2 my-1 flex">
                      <p className="me-2">&bull; </p> <h1 className="">{ele}</h1>
                    </div>
                  );
                })}
              </span>
              {(ele.driver.length >0 && ele.driver[0]!="" ) && <div>

                <h1 className="">ड्राइवरचे नाव </h1>
                {ele.driver.map((ele) => {
                  return (
                    <div className="ms-2 my-1 flex">
                      <p className="me-2">&bull; </p> <p className="">{ele}</p>
                    </div>
                  );
                })}
                </div>
              }
              {(ele.sutti_maker.length >0 && ele.sutti_maker[0]!="" ) && <div>
                <h1 className="">सुट्टी मेकर </h1>
                {ele.sutti_maker.map((ele) => {
                  return (
                    <div className="ms-2 my-1 flex">
                      <p className="me-2">&bull; </p> <p className="">{ele}</p>
                    </div>
                  );
                })}
              </div>}
              <div>
                <h1 className="my-1" ><img
                        width={"16px"}
                        className="inline"
                        src={addsym}
                        alt=""
                      />{" "}
                      पत्ता </h1>
                <div className="ms-4">
                  {ele.address.village && (
                    <p>&bull; गावाचे नाव :- {ele.address.village} </p>
                  )}
                  {ele.address.taluka && (
                    <p>&bull; तालुका :- {ele.address.taluka} </p>
                  )}
                  {ele.address.district && (
                    <p>&bull; जिल्हा :- {ele.address.district} </p>
                  )}
                </div>
              </div>
              {(ele.contact && ele.contact !== -1 )&& 
                <h1 className="flex">    <p className="me-2 mt-1 ">
                <BiSolidPhoneCall />{" "}
              </p>
             <p className="" > संपर्क क्रमांक :-  <a className="text-blue-600 underline" href={`tel:${ele.contact}`}>{ele.contact}</a></p></h1>
            }
              {ele.image.length > 1 && (
                <div>
                  <h1 className="my-1">फोटो </h1>
                  <div className="flex whitespace-nowrap overflow-x-auto">
                    {ele.image.map((ele) => {
                      return (
                        <img
                          onClick={() => {
                            setpreViewImage(ele);
                            handleShowDialog();
                          }}
                          className="h-28 mx-0.5"
                          src={ele}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {(ele.winrace.length >0 && ele.winrace[0]!="" ) &&         (        <span>
                  <h1 className="">जिंकलेल्या शर्यतींची नावे </h1>
                  {ele.winrace.map((ele) => {
                    return (
                      <div className="ms-2 my-1 flex">
                        <p className="me-2">&bull; </p>{" "}
                        <p className="">{ele}</p>
                      </div>
                    );
                  })}
                </span>
              )}
              <h1 className="">पळण्याची बाजू (खांदा) :- {ele.runside}</h1>
              {ele.details && (
                <span>
                  <h1 className="">बैलाविषयी थोडक्यात माहिती :-</h1>
                  <p className="ms-2"> {ele.details} </p>
                </span>
              )}
              {ele.instagram && (
                <span>
                  <h1 className="">इंस्टाग्राम (Instagram ID)  </h1>
                  <p className="block overflow-x-hidden ms-2">
                  <a
                    className=" text-blue-600 underline "
                    href={ele.instagram}
                    target="_blank"
                  >
                    {ele.instagram}
                  </a></p>
                </span>
              )}
            </div>
          </div>
          <WrongInformation/>
        </div>
      ) : (
        <div className="z-10 sticky h-screen  top-1/3">
          <Spinner />
        </div>
      )}

    </div>
  );
}
