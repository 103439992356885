import React from "react";

export default function MaidanCard(props) {
  const { name, _id, image, fee, village, taluka, handle } = props;

  return (
    <div
      key={name}
      onClick={() => {
        handle(name, _id);
      }}
      className="bg-white mt-3 my-5 lg:mt-3 lg:my-0  h-60 lg:h-96 w-full bg-cover bg-top  border-neutral-800 border rounded relative "
      style={{
        backgroundImage: `url(${image})`,
        height: "70vh",
        maxWidth: "360px",
      }}
    >
      <div className="h-full w-full bg-gradient-to-t from-orange-500 from-20% via-transparent via-45% to-transparent to-45%">
        {fee !== -1 && (
          <div className="absolute bg-black text-white -right-1 -top-1 text-xs rounded-3xl p-1">
            {fee}
          </div>
        )}
        <div className="  pb-1 absolute bottom-0 w-2/3  translate-x-1/4  text-white rounded-lg   ">
          <h3 className="mt-1 font-bold bg-white text-black rounded-xl ">
            आता पहा
          </h3>
          <p className="text-xs my-1 ">
            {village}, {taluka}
          </p>
        </div>
      </div>
    </div>
  );
}

{
  /* <div
key={index}
onClick={() => {
  handle(name, _id);
}}
className="bg-white width1 mt-3      border-neutral-400 border rounded "
>
<img
  className="w-full h-24 object-cover lg:h-56 rounded "
  src={image}
  alt=""
/>
<div className=" border-neutral-600 border-t mb-1 ">
  <h1 className="mt-1 font-medium">{ele.name}</h1>
  <p className="text-xs ">दिनांक : {formattedDate}</p>
</div>
</div> */
}
