import React, { useEffect } from 'react'
import ReactGA from "react-ga4";


export default function Error() {

  useEffect(()=>{
    document.title = 'Bailgada Live - Page Not Found | बैलगाडा Live';

    ReactGA.send({ hitType: "pageview", page: window.location.pathname , title: "Error Page Title" });
  },[]) ;

  return (
    <div className='pt-20 h-screen '>
      <h1 className='text-center' >Page Not Found</h1>
    </div>
  )
}
