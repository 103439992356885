import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../Components/Spinner";
import ReactGA from "react-ga4";
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Youtube from "../Images/y.webp";
import WrongInformation from "../Components/WrongInformation";
import datesym from "../Images/date-calendar.png";
import feesym from "../Images/fees.png";
import comsym from "../Images/commentator.png";
import zendasym from "../Images/referee.png";
import addsym from "../Images/location.png";

export default function MaidanItem() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate() ;
  

  const [item, setitem] = useState({});
  const [date, setdate] = useState("");
  const [isLoad, setload] = useState(false);
  const [flag, setflag] = useState(false);
  const getbail = async () => {
    try {
      const data1 = await axios.get(
        `${baseUrl}/api/maidan/get/${params.id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const date1 = new Date(data1.data.date);
      const specificDate = new Date(2024, 1, 7);

      if (date1 > specificDate) {
        setflag(true);
      }
      var formattedDate = date1
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .split("/")
        .join("/");

      setdate(formattedDate);
      setitem(data1.data);
      console.log(date1);
      setload(true);
    } catch (error) {
      toast.error("Failed To Submit. Try Again");
    }
  };

  const [isPreview, setPreview] = useState(false);
  const [preViewImage, setpreViewImage] = useState(null);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const handleShowDialog = (e) => {
    setPreview(!isPreview);
    console.log("cliked");
  };

  const params = useParams();
  useEffect(() => {
    document.title = `${params.name} Bailgada Live | बैलगाडा Live`;

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Specific maidan PAGE TITLE",
    });
    getbail();
  }, []);

  const handle2 = (name, id) => {
    const formattedName = name.replace(/\s+/g, "-");
    ReactGA.event({
      category: name,
      action: "Bail Click On Home PAge",
      label: "Home Page Bails",
      value: id,
    });

    navigate(`/bail/${formattedName}/${id}`);
  };

  return ( 
    <div className="pt-16 bg-stone-100 ">
      {isLoad ? (
        <div className="lg:px-96 lg:my-4">
          <Modal
            isOpen={isPreview}
            onRequestClose={handleShowDialog}
            style={customStyles}
          >
            <div className="">
              <div className="relative py-2 ">
                <button
                  className="text text absolute z-50 right-0 -top-3 text-2xl"
                  onClick={handleShowDialog}
                >
                  <IoMdClose />
                </button>
              </div>
              <div className=" ">
                <img
                  className="image"
                  style={{ maxHeight: "90vh" }}
                  src={preViewImage}
                  alt="no image"
                />
              </div>
            </div>
          </Modal>
          <div
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            // TODO Sticky
            className={`bg-black    text-white p-3 text-center ${
              isPreview && "hidden"
            }`}
          >
            <h1 className="text-3xl  font-custom1">{item.name}</h1>
          </div>

          <div className="mx-0.5 border border-black rounded mt-0.5">
            <div className=" ">
              <img
                onClick={() => {
                  setpreViewImage(item.image[0]);
                  handleShowDialog();
                }}
                className="lg:w-96 lg:mx-auto rounded"
                src={item.image[0]}
                alt=""
              />
            </div>
            <div className="">
              {item.winners.length > 0 && (
                <div className="bg-pink-200  p-1  pb-3">
                  <h1 className="ps-4 text-center text-2xl my-2">निकाल</h1>
                  
                  {flag == true
                    ? <div className="mx-2" > {item.winners.map((ele, index) => {
                        return (
                          <div className="bg-pink-400 rounded-lg bg-gradient-to-r from-pink-600  via-white via-60% to-red-950 from-5%  p-2 flex gap-1 my-1 py-4" >
                             <p className="bg-black inline rounded-full px-2 lg:px-3 text-white" > {index + 1}</p>
                              <div className=" grow text-center " >
                                <h5 className="font-bold font- text-" >{ele.gadi}</h5>
                                <div className=" font-custom1 text-2xl" >
                              <span
                                className={`${
                                  ele.id1
                                    ? "underline underline-offset-2 text-blue-700 "
                                    : ""
                                }font-semibold`}
                                onClick={() => {
                                  ele.id1 && handle2(ele.name1, ele.id1);
                                }}
                              >
                                {ele.name1}
                              </span>
                              
                              {" "}-{" "}
                              <span
                              className={`${
                                ele.id2
                                  ? "underline underline-offset-2 text-blue-400 "
                                  : ""
                              } font-semibold `}
                                onClick={() => {
                                  ele.id2 && handle2(ele.name2, ele.id2);
                                }}
                              >
                                {ele.name2}
                              </span></div>
                              {ele.driver && <h5 className="font-medium" >{ele.driver}</h5>}
                              </div>
                          </div>
                        );
                      }) }</div>
                    : item.winners.map((ele, index) => {
                        return (
                          <div>
                            <h1>
                              {index + 1}. {ele.bail1} - {ele.bail2}
                            </h1>
                          </div>
                        );
                      })}
                  <p className="my-2 text-xs">{item.nikaltip}</p>
                </div>
              )}
              {item.winnerposters.length > 0 && (
                <div className="p-2 bg-yellow-100 gap-2 py-2">
                  <h1 className="text-center text-2xl my-2">
                    विजेत्या बैलांची पोस्टर
                  </h1>
                  <div className="mx-2 overflow-x-auto whitespace-nowrap ">
                    <div className="flex ">
                      {item.winnerposters.map((ele) => {
                        return (
                          <img
                            onClick={() => {
                              setpreViewImage(ele);
                              handleShowDialog();
                            }}
                            className="border border-black mx-1 rounded "
                            width={"100px"}
                            src={ele}
                            alt=""
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              {item.nikalvideo.length > 0 && (
                <div className="p-2 bg-yellow-200 gap-2 pb-4">
                  <h1 className="text-center text-2xl my-2">
                    निकालाचा व्हिडीओ{" "}
                  </h1>
                  <div className="mx-2 overflow-x-auto whitespace-nowrap ">
                    <div className="flex  ">
                      {item.nikalvideo.map((ele) => {
                        return (
                          <div className="mx-1">
                            <iframe
                              width="250"
                              height="150"
                              src={ele}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowfullscreen
                            ></iframe>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="py-2 px-0.5 bg-gray-100 rounded">
              <div className=" px-1  ">
                <h1 className="my-3">
                  <img width={"16px"} className="inline" src={datesym} alt="" />{" "}
                  मैदानाची दिनांक :- {date}
                </h1>
                {item.fee != -1 && (
                  <h1 className="my-3">
                    <img
                      width={"16px"}
                      className="inline"
                      src={feesym}
                      alt=""
                    />{" "}
                    प्रवेश फी :- {item.fee}
                  </h1>
                )}
                {item.comentetor.length > 0 && item.comentetor[0] != "" && (
                  <div className="my-3">
                    <h1 className="inline">
                      <img
                        width={"16px"}
                        className="inline"
                        src={comsym}
                        alt=""
                      />{" "}
                      समालोचक{" "}
                    </h1>
                    {item.comentetor.map((ele) => {
                      return <p className="ms-4">&bull; {ele}</p>;
                    })}
                  </div>
                )}

                {item.zendapanch.length > 0 && item.zendapanch[0] != "" && (
                  <div className="my-2">
                    <h1 className=" inline">
                      <img
                        width={"16px"}
                        className="inline"
                        src={zendasym}
                        alt=""
                      />{" "}
                      झेंडापंच{" "}
                    </h1>
                    {item.zendapanch.map((ele) => {
                      return <p className="ms-4">&bull; {ele}</p>;
                    })}
                  </div>
                )}
                <div className="my-2">
                  <h1>
                    <img
                      width={"16px"}
                      className="inline"
                      src={addsym}
                      alt=""
                    />{" "}
                    पत्ता
                  </h1>
                  <div className="ms-4">
                    {item.address.address && (
                      <h3 className="my-1">
                        <p className=" font-custom2">&bull; स्थळ </p>{" "}
                        <p className=" ms-4 font-normal">
                          {item.address.address}
                        </p>
                      </h3>
                    )}{" "}
                    <h2 className="my-0.5">
                      &bull; गाव :- {item.address.village}
                    </h2>
                    <h2 className="my-0.5">
                      &bull; तालुका :- {item.address.taluka}
                    </h2>
                    <h2 className="my-0.5">
                      &bull; जिल्हा :- {item.address.district}
                    </h2>
                  </div>
                </div>
                {item.location && (
                  <div className="my-2">
                    <h1 className=" inline">लोकेशन लिंक (Google Maps) :- </h1>
                    <a
                      className="underline text-blue-600"
                      target="_blank"
                      href={item.location}
                    >
                      Google Maps
                    </a>
                  </div>
                )}
                {item.contact.length > 0 && (
                  <div className="my-3">
                    <h1 className="  inline">संपर्क क्रमांक</h1>
                    {item.contact.map((ele) => {
                      return (
                        <div className="ms-2 my-1 flex align-middle">
                          <p className="me-2 mt-1.5">
                            <BiSolidPhoneCall />{" "}
                          </p>
                          <a href={`tel:${ele.phone_no}`} className="  ">
                            <p className="inline ">{ele.name}</p> -{" "}
                            <p className="inline underline text-blue-500">
                              {ele.phone_no}
                            </p>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                )}
                {item.image.length > 0 && (
                  <div className="my-3">
                    <h1 className="my-1">फोटो</h1>
                    <div className="flex whitespace-nowrap overflow-x-auto">
                      {item.image.map((ele) => {
                        return (
                          <img
                            onClick={() => {
                              setpreViewImage(ele);
                              handleShowDialog();
                            }}
                            className="h-28 mx-0.5 border border-black rounded"
                            src={ele}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
                {item.rules.length > 0 && (
                  <div className="my-3">
                    <h1 className="my-1">शर्यतीचे नियम व अटी</h1>
                    {item.rules.map((ele) => {
                      return (
                        <div className="ms-2 my-1 flex">
                          <p className="me-2">&bull;</p>
                          <p className=""> {ele}</p>
                        </div>
                      );
                    })}
                  </div>
                )}
                {item.guidelines && (
                  <div className="my-2">
                    <h1 className="my-1">विशेष सूचना </h1>
                    {item.guidelines.map((ele) => {
                      return (
                        <div className="ms-2 my-1 flex">
                          <p className="me-2">&bull;</p>
                          <p className=""> {ele}</p>
                        </div>
                      );
                    })}
                  </div>
                )}
                {item.information && (
                  <div className="my-3">
                    <h1 className="my-1"> मैदानाबद्दल माहिती </h1>
                    <p className="ms-2">{item.information}</p>
                  </div>
                )}
                {item.youtubechannel.length > 0 && (
                  <div className="my-3">
                    <h1 className="my-1">लाइव्ह युट्युब चॅनेल </h1>
                    {item.youtubechannel.map((ele) => {
                      return (
                        <a
                          className=" text-blue-600 ms-1 block"
                          href={ele.link}
                        >
                          <p className="text-black no-underline  inline">
                            <img className="inline w-8" src={Youtube} alt="" />
                          </p>{" "}
                          <p className="underline inline">{ele.name}</p>
                        </a>
                      );
                    })}
                  </div>
                )}
              </div>
              {item.videos.length > 0 && (
                <div className="p-2 bg-yellow-200 rounded ">
                  <h1 className="  my-1">मैदानाचे विडिओ</h1>

                  <div className="mt-1  overflow-x-auto whitespace-nowrap mb-2">
                    <div className="flex ">
                      {item.videos.map((ele) => {
                        return (
                          <div className="mx-1">
                            <iframe
                              width="250"
                              height="150"
                              src={ele.link}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowfullscreen
                            ></iframe>
                            <p className=" overscroll-none text-sm text-center mt-1 whitespace-normal">
                              {ele.title}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <WrongInformation />
        </div>
      ) : (
        <div className="z-10 sticky h-screen mt-20">
          <Spinner />
        </div>
      )}
    </div>
  );
}
