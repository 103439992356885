import axios from "axios";
import React, { useEffect, useState } from "react";
import Spinner from "../Components/Spinner";
import ReactGA from "react-ga4";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { toast } from "react-toastify";

export default function Bailinput() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [isLoading, setLoading] = useState(false);
  const [preViewImage, setpreViewImage] = useState(null);
  const [isPreview, setPreview] = useState(false);

  const [formdata, setdata] = useState({
    name: "",
    tempname: "",
    gadi_name: "",
    type: "",
    malak: "",
    details: "",
    driver: "",
    sutti_maker: "",
    runside: "",
    district: "",
    taluka: "",
    village: "",
    contact: null,
    instagram: "",
    image: [],
  });

  const [winrace, setwinrace] = useState([""]);

  useEffect(() => {
    document.title =
      "Bailgada Live - Register Your Bull | बैलगाडा Live - बैलाची नोंद";
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Baiinput Title",
    });
  }, []);

  const handleShowDialog = (e) => {
    e.preventDefault();
    setPreview(!isPreview);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setdata({ ...formdata, [name]: value });
  };

  const handleInputImage = (e) => {
    const file = e.target.files;
    setdata({ ...formdata, image: Array.from(file) });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    try {
      ReactGA.event({
        category: "Input bail",
        action: "Bail INput submit On Bailinput page",
        label: "Bail INput event  ",
        value: formdata.name,
      });

      const apiFormData = new FormData();
      apiFormData.append("name", formdata.name.replace(/\s+$/, ""));

      apiFormData.append("tempname", formdata.tempname);
      apiFormData.append("gadi_name", formdata.gadi_name);
      apiFormData.append("sutti_maker", formdata.sutti_maker);
      apiFormData.append("type", formdata.type);
      apiFormData.append("driver", formdata.driver);
      apiFormData.append("malak", formdata.malak);
      apiFormData.append("details", formdata.details);
      apiFormData.append("runside", formdata.runside);
      apiFormData.append("district", formdata.district);
      apiFormData.append("taluka", formdata.taluka);
      apiFormData.append("village", formdata.village);
      apiFormData.append("contact", formdata.contact);
      apiFormData.append("instagram", formdata.instagram);
      apiFormData.append("winrace", JSON.stringify(winrace));

      formdata.image.forEach((ele, index) => {
        apiFormData.append(`image${index + 1}`, ele);
      });

      const url = `${baseUrl}/api/bail/insert`;

      const response = await axios.post(url, apiFormData, {
        headers: {
          "Content-Type": "'multipart/form-data",
        },
      });
      toast.success("Succesfuly submited");
    } catch (error) {
      console.log(error);
      toast.error("Failed To Submit. Try Again");
    } finally {
      setLoading(false);
    }
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const handleFormChange = (index, event) => {
    const { value } = event.target;
    const data = [...winrace];
    data[index] = value;
    setwinrace(data);
  };
  const handleRemove = (event, index) => {
    event.preventDefault();
    if (index == 0) {
      return;
    }
    let data = [...winrace];
    data.splice(index, 1);
    setwinrace(data);
  };
  const addfield = (e) => {
    e.preventDefault();
    setwinrace([...winrace, ""]);
  };

  return (
    <div className="pt-16 bg-stone-100 lg:px-96 lg:pt-20 ">
      <Modal
        isOpen={isPreview}
        onRequestClose={handleShowDialog}
        style={customStyles}
      >
        <div className="relative py-2">
          <button
            className="text text absolute right-0 -top-3 text-2xl"
            onClick={handleShowDialog}
          >
            <IoMdClose />
          </button>
        </div>
        <div className=" ">
          <img className="image " src={preViewImage} alt="no image" />
        </div>
      </Modal>
      <Modal isOpen={isLoading} style={customStyles}>
        <div className="p-2">
          <Spinner />
        </div>
      </Modal>
      <div className="text text-center">
        <h1 className="border-b-2 px-2 border-grey-400 w-full mb-4 py-3 text-lg  bg-black text-white">
          आपल्या बैलाची माहिती द्या{" "}
        </h1>
        <h6 className="px-2 text-start text-xs mt-2"></h6>
      </div>
      <form action="" onSubmit={handleSubmit}>
        <table className="mt-4 mx-2 lg:mx-auto">
          <div className="mb-4">
            <label className="text-sm font-semibold" htmlFor="name">
              बैलाचे नाव
            </label>
            <input
              className=" p-1 border-grey-400 w-full rounded  border-2 mt-1"
              type="text"
              id="name"
              name="name"
              value={formdata.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-4 ">
            <label className="text-sm font-semibold" htmlFor="tempname">
              बैलाचे टोपण नाव <p className="text-xs inline">(पर्यायी)</p>
            </label>
            <input
              className=" p-1 border-grey-400 w-full rounded  border-2 mt-1"
              type="text"
              id="tempname"
              name="tempname"
              value={formdata.tempname}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4 ">
            <label className="text-sm font-semibold" htmlFor="gadi_name">
              गाडीचे नाव
            </label>
            <input
              className=" p-1 border-grey-400 w-full rounded border-2 mt-1"
              type="text"
              id="gadi_name"
              name="gadi_name"
              value={formdata.gadi_name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="mb-4">
            <label className="text-sm font-semibold" htmlFor="malak">
              मालकाचे नाव
            </label>
            <input
              className="p-1 border-grey-400 w-full rounded  border-2 mt-1"
              type="text"
              id="malak"
              name="malak"
              value={formdata.malak}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-4 text-sm">
            <h6 className="text-sm font-semibold" htmlFor="">
              बैलाची जात
            </h6>
            <div className="mx-2 mt-1">
              <div className="">
                <input
                  className=" p-1 border-grey-400  me-3 border-2"
                  type="radio"
                  id="type1"
                  name="type"
                  value="खिल्लार"
                  onChange={handleInputChange}
                  required
                />
                <label htmlFor="type1">खिल्लार</label>
              </div>
              <div>
                <input
                  className="p-1 border-grey-400 me-3  border-2"
                  type="radio"
                  id="type2"
                  name="type"
                  value="म्हैसूरी"
                  onChange={handleInputChange}
                  required
                />
                <label htmlFor="type2">म्हैसूरी</label>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h4 className="block text-sm font-semibold" htmlFor="">
              पळण्याचा खांदा
            </h4>
            <div className="flex flex-row justify-around text-center mt-1 text-sm">
              <div>
                <input
                  className=" p-1 border-grey-400  border-2"
                  type="radio"
                  id="left1"
                  name="runside"
                  value={"डावी बाजू"}
                  onChange={handleInputChange}
                  required
                />
                <label className="mx-2" htmlFor="left1">
                  डावा खांदा
                </label>
              </div>
              <div>
                <input
                  className=" p-1 border-grey-400   border-2"
                  type="radio"
                  id="left2"
                  name="runside"
                  value="उजवी बाजू"
                  onChange={handleInputChange}
                  required
                />
                <label className="mx-2" htmlFor="left2">
                  उजवा खांदा
                </label>
              </div>
              <div>
                <input
                  className=" p-1 border-grey-400  border-2"
                  type="radio"
                  id="left3"
                  name="runside"
                  value="दोन्ही बाजू"
                  onChange={handleInputChange}
                  required
                />
                <label className="mx-2" htmlFor="left3">
                  दोन्ही खांदा
                </label>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <label className="text-sm font-semibold" htmlFor="driver">
              ड्राइवरचे नाव <p className="text-xs inline">(पर्यायी)</p>
            </label>
            <input
              className=" p-1 mt-1 border-grey-400 rounded w-full  border-2"
              type="text"
              id="driver"
              name="driver"
              value={formdata.driver}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label className="text-sm font-semibold" htmlFor="sutti_maker">
              सुट्टी मेकर <p className="text-xs inline">(पर्यायी)</p>
            </label>
            <input
              className=" p-1 mt-1 border-grey-400 rounded w-full border-2"
              type="text"
              id="sutti_maker"
              name="sutti_maker"
              value={formdata.sutti_maker}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label className="text-sm font-semibold" htmlFor="village">
              गाव
            </label>
            <input
              className="w-full p-1 rounded mt-1 border-grey-400   border-2"
              type="text"
              id="village"
              name="village"
              value={formdata.village}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="flex flex-row gap-2 mb-4">
            <div className="">
              <label className="text-sm font-semibold" htmlFor="district">
                जिल्हा
              </label>
              <div>
                <select
                  class="mt-1 block w-full rounded border-0 py-1 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 "
                  onChange={handleInputChange}
                  value={formdata.district}
                  name="district"
                  id="district"
                >
                  <option>Select District</option>
                  <option value="Satara">Satara</option>
                  <option value="Sangli">Sangli</option>
                  <option value="Pune">Pune</option>
                  <option value="Ahmednagar">Ahmednagar</option>
                  <option value="Akola">Akola</option>
                  <option value="Amravati">Amravati</option>
                  <option value="Chhatrapati Sambhaji Nagar">
                    Chhatrapati Sambhaji Nagar
                  </option>
                  <option value="Beed">Beed</option>
                  <option value="Bhandara">Bhandara</option>
                  <option value="Buldhana">Buldhana</option>
                  <option value="Chandrapur">Chandrapur</option>
                  <option value="Dhule">Dhule</option>
                  <option value="Gadchiroli">Gadchiroli</option>
                  <option value="Gondia">Gondia</option>
                  <option value="Hingoli">Hingoli</option>
                  <option value="Jalgaon">Jalgaon</option>
                  <option value="Jalna">Jalna</option>
                  <option value="Kolhapur">Kolhapur</option>
                  <option value="Latur">Latur</option>
                  <option value="Navi Mumbai">Navi Mumbai</option>
                  <option value="Nagpur">Nagpur</option>
                  <option value="Nanded">Nanded</option>
                  <option value="Nandurbar">Nandurbar</option>
                  <option value="Nashik">Nashik</option>
                  <option value="Dharashiv">Dharashiv</option>
                  <option value="Parbhani">Parbhani</option>
                  <option value="Raigad">Raigad</option>
                  <option value="Ratnagiri">Ratnagiri</option>
                  <option value="Sindhudurg">Sindhudurg</option>
                  <option value="Solapur">Solapur</option>
                  <option value="Thane">Thane</option>
                  <option value="Washim">Washim</option>
                  <option value="Washim">Washim</option>
                  <option value="Yavatmal">Yavatmal</option>
                </select>
              </div>
            </div>
            <div className="">
              <label className="text-sm font-semibold" htmlFor="taluka">
                तालुका
              </label>
              <br />
              <input
                className="mt-1 p-0.5 w-full rounded border-grey-300 border-2 "
                type="text"
                onChange={handleInputChange}
                value={formdata.taluka}
                name="taluka"
                id="taluka"
                required
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="text-sm font-semibold" htmlFor="contact">
              संपर्क क्रमांक
            </label>
            <input
              className="w-full mt-1 rounded p-1 border-grey-400   border-2"
              type="Number"
              id="contact"
              name="contact"
              value={formdata.contact}
              onChange={handleInputChange}
              required
            />
          </div>
        </table>
        <div className=" text-black px-2">
          <div className="mb-4">
            <label className="text-sm font-semibold" htmlFor="image">
              बैलाचे फोटो
            </label>
            <input
              className="w-full p-1 mt-1 rounded border-grey-400  border-2"
              accept="image/*"
              type="file"
              multiple
              id="contact"
              name="image"
              onChange={handleInputImage}
              required
            />
            {formdata.image.length > 0 && (
              <div className="my-2">
                <button
                  className=" underline underline-offset-2 text-xs "
                  onClick={handleShowDialog}
                >
                  फोटो पाहण्यासाठी फोटोवर क्लिक करा{" "}
                </button>
                <div className="flex whitespace-nowrap overflow-x- gap-2 mt-1 ">
                  {formdata.image.map((ele) => {
                    return (
                      <img
                        className="border border-black rounded"
                        onClick={(e) => {
                          setpreViewImage(URL.createObjectURL(ele));
                          handleShowDialog(e);
                        }}
                        width={"50px"}
                        src={URL.createObjectURL(ele)}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="mb-4">
            <label className="text-sm font-semibold" htmlFor="details">
              बैलाविषयी माहिती <p className="text-xs inline">(पर्यायी)</p>
            </label>
            <textarea
              className="w-full mt-1 rounded p-1 border-grey-400  border-2"
              type="text"
              id="details"
              name="details"
              value={formdata.details}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label className="text-sm font-semibold" htmlFor="winrace">
              जिंकलेल्या मैदानाची नावे{" "}
              <p className="text-xs inline">(पर्यायी)</p>
            </label>
            {winrace.map((ele, index) => {
              return (
                <div className="flex gap-2 my-2">
                  <p>{index + 1}.</p>
                  <input
                    className="w-full px-1 py-0.5 rounded border-grey-400   border-2"
                    type="text"
                    id="winrace"
                    name="winrace"
                    value={ele}
                    onChange={(e) => {
                      handleFormChange(index, e);
                    }}
                  />
                  <button
                    onClick={(event) => {
                      handleRemove(event, index);
                    }}
                    className={` border-2 px-2 rounded active:scale-90 border-grey-300`}
                  >
                    -
                  </button>
                </div>
              );
            })}
            <div className="text-center mt-1">
              <button
                onClick={addfield}
                className="w-full text-center px-5 rounded border-2 text-sm py-0.5 border-grey-600 active:scale-90 transition-all   "
              >
                आणखी जोडा
              </button>
            </div>
          </div>
        </div>
        <div className="mb-4 mx-2">
          <label className="text-sm font-semibold" htmlFor="instagram">
            इंस्टाग्राम <p className="text-xs inline">(पर्यायी)</p>
          </label>
          <input
            className="w-full p-1 border-grey-400 border-2 rounded mt-1"
            type="text"
            id="instagram"
            name="instagram"
            value={formdata.instagram}
            onChange={handleInputChange}
          />
        </div>
        <div className="my-2 text-center">
          <button
            type="submit"
            className="bg-black text-white p-1 px-10 rounded active:scale-90 ease-in-out transition"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
