import { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import ReactGA from "react-ga4";
import { IoMdClose } from "react-icons/io";
import Spinner from "../Components/Spinner";
import { toast } from "react-toastify";

export default function Maidaninput() {
  useEffect(() => {
    document.title =
      "Bailgada Live - Register Your Maidan | बैलगाडा Live - मैदानाची माहिती नोंद करा";
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "maidan Input Title",
    });
  }, []);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [isPreview, setPreview] = useState(false);
  const [sumitmodalopen, setsumitmodalopen] = useState(false);

  const [formdata, setdata] = useState({
    name: "",
    date: null,
    fee: null,
    comentetor: "",
    zendapanch: "",
    address: {
      village: "",
      district: "",
      taluka: "",
      address: "",
    },
    location: "",
    guidelines: "",
    information: "",
    youtubechannel: [],
  });

  const [contact, setcontact] = useState([{ name: "", phone_no: null }]);
  const [rules, setRules] = useState([""]);

  const [banner, setbanner] = useState([]);
  const [preViewImage, setpreViewImage] = useState([]);

  const handleShowDialog = (e) => {
    e.preventDefault();
    setPreview(!isPreview);
    console.log("cliked");
  };

  function handleImageChange(e) {
    const file = e.target.files;
    setbanner(Array.from(file));
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (name.includes(".")) {
      const [parentKey, childKey] = name.split(".");
      setdata((prevData) => ({
        ...prevData,
        [parentKey]: {
          ...prevData[parentKey],
          [childKey]: value,
        },
      }));
    } else {
      setdata((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setsumitmodalopen(true);
    ReactGA.event({
      category: "MAIDAN input CATEGORY",
      action: "MAIDAN input SUBMIT CLICKED ON ALL MAIDANINPUT PAGE",
      label: "MAIDANINPUT CLICK EVENT",
      value: formdata.name,
    });
    try {
      const apiFormData = new FormData();
      apiFormData.append("name", formdata.name.replace(/\s+$/, ""));
      apiFormData.append("date", formdata.date);
      apiFormData.append("fee", formdata.fee);
      apiFormData.append("comentetor", formdata.comentetor);
      apiFormData.append("zendapanch", formdata.zendapanch);
      apiFormData.append("village", formdata.address.village);
      apiFormData.append("district", formdata.address.district);
      apiFormData.append("taluka", formdata.address.taluka);
      apiFormData.append("address", formdata.address.address);
      apiFormData.append("location", formdata.location);
      apiFormData.append("contact", JSON.stringify(contact));
      apiFormData.append("rules", JSON.stringify(rules));
      apiFormData.append("guidelines", formdata.guidelines);
      apiFormData.append("information", formdata.information);
      // apiFormData.append("image", banner);
      banner.forEach((ele, index) => {
        apiFormData.append(`image${index + 1}`, ele);
      });
      console.log(apiFormData);

      const url = `${baseUrl}/api/maidan/insert`;

      const response = await axios.post(url, apiFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Succesfuly submited");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed To Submit. Try Again");
    } finally {
      setsumitmodalopen(false);
    }
  };

  const addfield = (e) => {
    e.preventDefault();
    const data = { name: "", phone_no: null };
    setcontact([...contact, data]);
  };

  const addfield1 = (e) => {
    e.preventDefault();
    setRules([...rules, ""]);
  };

  const handleFormChange = (index, event) => {
    const { name, value } = event.target;
    const data = [...contact];
    data[index][name] = value;
    setcontact(data);
  };

  const handleFormChange1 = (index, event) => {
    const { value } = event.target;
    const data = [...rules];
    data[index] = value;
    setRules(data);
  };

  const handleRemove = (event, index) => {
    event.preventDefault();
    if (index == 0) {
      return;
    }
    let data = [...contact];
    data.splice(index, 1);
    setcontact(data);
  };

  const handleRemove1 = (event, index) => {
    event.preventDefault();
    if (index == 0) {
      return;
    }
    let data = [...rules];
    data.splice(index, 1);
    setRules(data);
  };

  return (
    <div className="pt-16 bg-stone-100 lg:px-96 lg:pt-20">
      <div className="text text-center">
        <h1 className="bg-black text-white py-3 text-lg">
          मैदानाची माहिती नोंद करा
        </h1>
        <h6 className="text-black text-start px-2 mt-2 text-xs"></h6>
      </div>
      <Modal
        isOpen={isPreview}
        onRequestClose={handleShowDialog}
        style={customStyles}
      >
        <div className="relative py-2">
          <button
            className="text text absolute right-0 -top-3 text-2xl"
            onClick={handleShowDialog}
          >
            <IoMdClose />
          </button>
        </div>
        <div className=" ">
          <img className="image " src={preViewImage} alt="no image" />
        </div>
      </Modal>
      <Modal isOpen={sumitmodalopen} style={customStyles}>
        <div className="p-2">
          <Spinner />
        </div>
      </Modal>
      <form onSubmit={handleSubmit} action="">
        <div className="  mt-4 text-black mx-2">
          <div className="">
            <label className="text-sm font-semibold" htmlFor="name">
              मैदानाचे नाव
            </label>
            <br />
            <input
              className="w-full border-gray-400 border rounded mt-1 p-1"
              type="text"
              onChange={handleChangeInput}
              value={formdata.name}
              name="name"
              id="name"
              required
            />
          </div>
          <div className="flex justify-between">
            <div className="mt-4">
              <label className="text-sm font-semibold" htmlFor="date">
                मैदानाची तारीख
              </label>
              <br />
              <input
                className=" border-gray-400 mt-1 border rounded p-1"
                type="date"
                onChange={handleChangeInput}
                value={formdata.date}
                name="date"
                id="date"
                required
              />
            </div>
            <div className="mt-4">
              <label className="text-sm font-semibold" htmlFor="fee">
                प्रवेश फी
              </label>
              <br />
              <input
                className=" border-gray-400 mt-1 border rounded p-1"
                type="Number"
                onChange={handleChangeInput}
                value={formdata.fee}
                name="fee"
                id="fee"
                required
              />
            </div>
          </div>
          <div className="mt-4">
            <label className="text-sm font-semibold" htmlFor="banner">
              हॅण्डबीलचा फोटो / मैदानाचा फोटो
            </label>
            <br />
            <input
              className="w-full border-gray-400 mt-1 border rounded"
              type="file"
              multiple
              accept="image/*"
              onChange={handleImageChange}
              name="banner"
              id="banner"
              required
            />
            {banner.length > 0 && (
              <div>
                <button
                  className=" underline text-xs underline-offset-1"
                  // onClick={handleShowDialog}
                >
                  फोटो पाहण्यासाठी फोटोवर क्लिक करा{" "}
                </button>
                <div className=" mt-1 flex gap-3 whitespace-nowrap overflow-x-auto">
                  {banner.map((ele) => {
                    return (
                      <img
                      className="border border-black rounded"
                        onClick={(e) => {
                          setpreViewImage(URL.createObjectURL(ele));
                          handleShowDialog(e);
                        }}
                        width={"50px"}
                        src={URL.createObjectURL(ele)}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="mt-4">
            <label className="text-sm font-semibold" htmlFor="comentetor">
              समालोचकाचे नाव
            </label>
            <br />
            <input
              className="w-full border-gray-400 border rounded mt-1 p-1"
              type="text"
              onChange={handleChangeInput}
              value={formdata.comentetor}
              name="comentetor"
              id="comentetor"
              required
            />
          </div>
          <div className="mt-4">
            <label className="text-sm font-semibold" htmlFor="zendapanch">
              झेंडा पंचाचे नाव <p className="text-xs inline">(पर्यायी)</p>
            </label>
            <br />
            <input
              className="w-full border-gray-400 border rounded mt-1 p-1"
              type="text"
              onChange={handleChangeInput}
              value={formdata.zendapanch}
              name="zendapanch"
              id="zendapanch"
            />
          </div>
          <div className="mt-4">
            <label className="text-sm font-semibold" htmlFor="address.village">
              गावाचे नाव
            </label>
            <br />
            <input
              className=" w-full border-gray-400 border rounded mt-1 p-1"
              type="text"
              onChange={handleChangeInput}
              value={formdata.address.village}
              name="address.village"
              id="address.village"
              required
            />
          </div>
          <div className="flex gap-2 ">
            <div className="mt-4">
              <label className="text-sm font-semibold" htmlFor="address.taluka">
                तालुका
              </label>
              <br />
              <input
                className="mt-1 w-full border-gray-400 border rounded p-0.5"
                type="text"
                onChange={handleChangeInput}
                value={formdata.address.taluka}
                name="address.taluka"
                id="address.taluka"
                required
              />
            </div>
            <div className="mt-4 ">
              <label className="text-sm font-semibold" htmlFor="address.district">
                जिल्हा
              </label>
              <div>
                <select
                  className="mt-1 block w-full rounded  py-1 pl-3 pr-10 text-gray-900 border  border-gray-400 "
                  onChange={handleChangeInput}
                  value={formdata.address.district}
                  name="address.district"
                  id="address.district"
                  required
                >
                  <option>Select District</option>
                  <option value="Satara">Satara</option>
                  <option value="Sangli">Sangli</option>
                  <option value="Pune">Pune</option>
                  <option value="Ahmednagar">Ahmednagar</option>
                  <option value="Akola">Akola</option>
                  <option value="Amravati">Amravati</option>
                  <option value="Chhatrapati Sambhaji Nagar">
                    Chhatrapati Sambhaji Nagar
                  </option>
                  <option value="Beed">Beed</option>
                  <option value="Bhandara">Bhandara</option>
                  <option value="Buldhana">Buldhana</option>
                  <option value="Chandrapur">Chandrapur</option>
                  <option value="Dhule">Dhule</option>
                  <option value="Gadchiroli">Gadchiroli</option>
                  <option value="Gondia">Gondia</option>
                  <option value="Hingoli">Hingoli</option>
                  <option value="Jalgaon">Jalgaon</option>
                  <option value="Jalna">Jalna</option>
                  <option value="Kolhapur">Kolhapur</option>
                  <option value="Latur">Latur</option>
                  <option value="Navi Mumbai">Navi Mumbai</option>
                  <option value="Nagpur">Nagpur</option>
                  <option value="Nanded">Nanded</option>
                  <option value="Nandurbar">Nandurbar</option>
                  <option value="Nashik">Nashik</option>
                  <option value="Dharashiv">Dharashiv</option>
                  <option value="Parbhani">Parbhani</option>
                  <option value="Raigad">Raigad</option>
                  <option value="Ratnagiri">Ratnagiri</option>
                  <option value="Sindhudurg">Sindhudurg</option>
                  <option value="Solapur">Solapur</option>
                  <option value="Thane">Thane</option>
                  <option value="Washim">Washim</option>
                  <option value="Washim">Washim</option>
                  <option value="Yavatmal">Yavatmal</option>
                </select>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <label className="text-sm font-semibold" htmlFor="address.address">
              स्थळ
            </label>
            <br />
            <textarea
              className="mt-1 w-full border-gray-400 border rounded p-1"
              type="text"
              onChange={handleChangeInput}
              value={formdata.address.address}
              name="address.address"
              id="address.address"
              required
            />
          </div>
          <div className="mt-4">
            <label className="text-sm font-semibold" htmlFor="location">
              लोकेशन लिंक (Google Maps){" "}
              <p className="text-xs inline">(पर्यायी)</p>
            </label>
            <br />
            <input
              className="mt-1 w-full border-gray-400 border rounded p-1 "
              type="text"
              onChange={handleChangeInput}
              value={formdata.location}
              name="location"
              id="location"
            />
          </div>
          <div className="mt-4">
            <label className="text-sm font-semibold" htmlFor="contact">
              संपर्क क्रमांक व नाव <p className="text-xs inline">(पर्यायी)</p>
            </label>

            {contact.map((ele, index) => {
              return (
                <div className="flex justify-evenly gap-2 mt-1">
                  <input
                    className=" px-1 py-0.5 border-gray-400 w-5/12 border rounded p-1"
                    type="text"
                    onChange={(event) => [handleFormChange(index, event)]}
                    value={ele.name}
                    name="name"
                    id="name"
                    placeholder="नाव"
                  />
                  <input
                    className="border-gray-400 w-5/12 border rounded p-1 "
                    type="Number"
                    onChange={(event) => {
                      handleFormChange(index, event);
                    }}
                    value={ele.phone_no}
                    name="phone_no"
                    id="phone_no"
                    placeholder="फोन नंबर"
                  />
                  <button
                    onClick={(event) => {
                      handleRemove(event, index);
                    }}
                    className={` border px-2 rounded active:scale-90 border-gray-400 p-1`}
                  >
                    -
                  </button>
                </div>
              );
            })}
            <div className="text-center mt-2">
              <button
                onClick={addfield}
                className="text-sm py-0.5 w-full text-center px-5 rounded border  border-gray-500 active:scale-90 transition-all   "
              >
                आणखी जोडा
              </button>
            </div>
          </div>
          <div className="mt-4">
            <label className="text-sm font-semibold" htmlFor="rules">
              शर्यतीचे नियम व अटी <p className="text-xs inline">(पर्यायी)</p>
            </label>
            {rules.map((ele, index) => {
              return (
                <div className="flex gap-2 mt-1">
                  <p>{index + 1}. </p>
                  <textarea
                    className="w-full border-gray-400 border rounded p-1"
                    type="text"
                    onChange={(event) => {
                      handleFormChange1(index, event);
                    }}
                    value={ele}
                    name="rules"
                    id="rules"
                  />
                  <button
                    onClick={(event) => {
                      handleRemove1(event, index);
                    }}
                    className={` border px-2 rounded active:scale-90 border-gray-400`}
                  >
                    -
                  </button>
                </div>
              );
            })}
            <div className="text-center mt-2">
              <button
                onClick={addfield1}
                className="text-sm  py-0.5 text-center w-full px-5 rounded border text-grey-300 border-gray-500 active:scale-90 transition-all "
              >
                आणखी जोडा
              </button>
            </div>
          </div>
          <div className="mt-4">
            <label className="text-sm font-semibold" htmlFor="guidelines">
              विशेष सूचना <p className="text-xs inline">(पर्यायी)</p>
            </label>
            <br />
            <textarea
              rows={6}
              className="mt-1 w-full border-gray-400 border rounded p-1"
              type="text"
              onChange={handleChangeInput}
              value={formdata.guidelines}
              name="guidelines"
              id="guidelines"
            />
          </div>
          <div className="mt-4">
            <label className="text-sm font-semibold" htmlFor="information">
              मैदानाबद्दल माहिती <p className="text-xs inline">(पर्यायी)</p>
            </label>
            <br />
            <textarea
              rows={6}
              className="mt-1 w-full border-gray-400 border rounded p-1"
              type="text"
              onChange={handleChangeInput}
              value={formdata.information}
              name="information"
              id="information"
            />
          </div>
          <div className="my-3 text-center">
            <button
              type="submit"
              className="bg-black text-white p-1 px-10 rounded active:scale-90 ease-in-out transition"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
