import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import img from "../Images/cowson.jpg";

export default function About() {
  useEffect(() => {
    document.title = "Bailgada Live - About Us | बैलगाडा Live - आमच्याविषयी";
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "About Page Title",
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pt-16 lg:px-96 bg-stone-100">
      <h1 className="bg-black text-white text-2xl font-bold p-4 pt-6">
        आमच्याविषयी
      </h1>

      <p className="mt-1 px-2">
        नमस्कार, आमच्या वेबसाइटवर आपले स्वागत आहे! इथे आपणास बैलगाडी क्षेत्रातील
        विविध बैलांची माहिती पाहायला मिळेल. आपण बैलगाडीची मैदाने ,त्याबद्दल
        माहिती, आणि त्यांचे निकाल आपल्याला इथे पाहायला मिळतील. या वेबसाइटद्वारे
        बैलगाडी मालकांना मैदानाची माहिती मिळेल. त्यामुळे बैलगाडी मालकांना हे
        सोयीस्कर ठरेल. त्या बरोबरच प्रेक्षकांना बैलगाडा क्षेत्रातील बैल, मैदाने
        आणि त्यांचे निकाल सोप्या पद्धतीने पाहता येतील.हा दृष्टिकोन ठेऊन हि
        वेबसाइट तयार करण्यात आली आहे.
      </p>
      <img className="mt-1 mb-2 px-1" src={img} alt="" />
      <p className="p-2">
        आपण आपल्या बैलाची माहिती आम्हांस देऊन आपला बैल इथे नोंदवू शकता. आपला बैल
        एका दिवसाच्या आत पडताळणी करून नोंदवला जाईल. आपला बैलाची माहिती देऊन
        आपणास मैदानात मदत होईल. आपण जे मैदान घेता त्याचा लाभ सर्व बैलगाडा
        मालकांना मिळावा यासाठी आपण आपले मैदान आमच्याबरोबर नोंदवून घ्यावे. मैदान
        नोंद केल्यानंतर एका दिवसाच्या आत पडताळणी करून मैदान नोंद करण्यात येईल.
        आपण योग्य माहितीत देऊन सहकार्य करावे. आमचे उद्दिष्ट म्हणजे बैलगाडी
        क्षेत्रात प्रगती करणे, बैलगाडी क्षेत्रात आपला हातभार लावणे जेणेकरून हे
        क्षेत्र निरंतर चालू राहावे. प्रेक्षकांना या मध्ये कसलीही अडचण येऊ नये.
        आपल्या गौमातेचे रक्षण व्हावे. आपली गाई प्रत्येक घरी दिसावी. आपल्या
        बैलांना वर कसलाही अन्याय होऊ नये. या साठी आम्ही प्रयत्न करू.आमच्या या
        प्रयत्नात आपल्या सहभागाची आणि सहकार्याची आवशक्यता आहे. आपली समर्थनं
        आम्हाला प्रेरित करतं. आपल्या सहकार्याची आमच्या प्रयत्नांना नवीन ऊर्जा
        देण्यासाठी आपला स्वागत आहे. तरी सर्वानी सहकार्य करून हे उद्दिष्ट पूर्ण
        करू. धन्यवाद, आमच्या वेबसाइटवर भेट देऊन आपल्या अभिप्राय कळवा.
      </p>
    </div>
  );
}
