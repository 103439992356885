import React from "react";
import { Link } from "react-router-dom";

export default function WrongInformation() {
  return (
    <div className=" border-neutral-600 border   text-center rounded  my-2 mx-1">
      <Link
        to="/contact"
        className=" flex flex-row justify-between active:scale-90 transition-all"
      >
        <p className=" text-center grow pt-1 font-semibold  text-sm">
          काही त्रुटी असतील तर{" "}
        </p>
        <button className="bg-black text-white px-3 py-1   ">
          संदेश पाठवा
        </button>
      </Link>
    </div>
  );
}
