import React, { useState } from "react";
import { Link } from "react-router-dom";
import jp from "../Images/logo2.png";



export default function Header(props) {

  const {showSidebar , setShowSidebar  } = props ;
  // const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const closeSidebar = () => {
    setShowSidebar(false);
  };

  return (
    <div className=" lg:hidden fixed w-screen bg-black z-20 ">
      <header className="bg-white border-b-2 border-black relative ">
        <div className="">
          <Link to="/">
            <div className="p-3 inline-flex">
              <img className="w-10" src={jp} alt="" />
              <h1 className="my-auto ms-2 font-extrabold  text-2xl text-black">
                बैलगाडा <span className="t">Live</span>
              </h1>
            </div>
          </Link>

          {/* <label
            className=" lg:hidden flex z-50  absolute top-4 mt-2 right-4  active:scale-90 cursor-pointer "
            onClick={toggleSidebar}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="bg-white"
              height="1.3em"
              viewBox="0 0 448 512"
            >
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
            </svg>
          </label> */}
        </div>
        <div className="relative z-30 lg:hidden ">
          <div
            className={` text-black bg-stone-100  text- sidebar z-40  ${
              showSidebar ? "open" : ""
            }`}
            onClick={closeSidebar}
          >
            <ul className="text-center text border-t-2 border-black z-40">
              <li className=" border-b-2 border-slate-200 py-3 z-30">
                <Link onClick={closeSidebar} to="/">
                  मुखपृष्ट
                </Link>
              </li>
              <li className=" border-b-2 border-slate-200 py-3">
                <Link onClick={closeSidebar} to="/bail">
                  आपली बैल
                </Link>
              </li>
              <li className=" border-b-2 border-slate-200 py-3 ">
                <Link onClick={closeSidebar} to="/maidan">
                  मैदाने
                </Link>
              </li>
              <li className=" border-b-2 border-slate-200 py-3 ">
                <Link onClick={closeSidebar} to="/contact">
                  संपर्क
                </Link>
              </li>
              <li className=" border-b-2 border-slate-200 py-3 ">
                <Link onClick={closeSidebar} to="/about">
                  आमच्यविषयी माहिती
                </Link>
              </li>
              {/* <li className=" border-b-2 border-slate-200 py-3 ">
                <a onClick={closeSidebar} target="_blanck" href="https://chat.whatsapp.com/JTlmuWVSIMmI7NVwTD9suD">व्हॉट्सॲप ग्रुप जॉईन करा</a>
            </li> */}
            </ul>
          </div>
        </div>
      </header>
    </div>
  );
}
