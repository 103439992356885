import React from 'react'
import spin from "../Images/Spinner-1s-200px (1).gif"

export default function Spinner() {
  return (
    <div className='flex justify-center align-middle'>
        <img className='w-24' src={spin} alt="" />
    </div>
  )
}
