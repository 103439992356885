import React from 'react'
import { CiInstagram } from "react-icons/ci";
// import { FaWhatsapp ,FaFacebook } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";


export default function Footer() {
  return (

    <div className=' bg-stone-200 bg- py-1 text-gray-500 text-center my-auto'>
      <div className='text-center flex flex-row justify-center gap-3 my-2'>
        <a href="https://www.instagram.com/bailgadalive/"  target="_blanck"> <CiInstagram className='text-2xl'/></a>
        <a href="mailto:livebailgada@gmail.com"  target="_blanck" ><IoIosMail className='text-2xl'/></a>
        {/* <a href="https://www.instagram.com/bailgadalive/"><FaWhatsapp className='text-2xl'/></a> */}
        {/* <a href="https://www.instagram.com/bailgadalive/"><FaFacebook className='text-2xl'/></a> */}
      </div>
      <p className='text text-xs font-thin font'>
      Copyright&copy;2023 bailgada.live</p>
    </div>
  )
}
