import React, { useEffect, useState } from "react";
import axios from "axios"
import Spinner from "../Components/Spinner";
import ReactGA from "react-ga4";
import {toast } from 'react-toastify';
import Slider from "react-slick";
import temp from "../Images/cowson.jpg"



export default function Contact() {

  useEffect(()=>{
    document.title = 'Bailgada Live - Contact | बैलगाडा Live - संपर्क';
    console.log(window.location.pathname) ;
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Contact Page Title" });
    window.scrollTo(0, 0);
  },[]) ;
  const baseUrl  = process.env.REACT_APP_BASE_URL ;
  const [isLoading , setLoading] = useState(false) ;
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email : "" ,
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async(e) => {
    setLoading(true);
    e.preventDefault();
    ReactGA.event({
      category : "COntact EVENT CATEGOTY" ,
      action : "CONTACT SUBMIT  Click On CONTACT PAge" ,
      label : "CONTACT EVENT" ,
      value :formData.contact ,
    });
    console.log("Submitted data:", formData);
    try {
      const response = await axios.post(`${baseUrl}/api/message/insert`, 
      formData
      );
      toast.success('Succesfuly submited');

      
    } catch (error) {
      console.error('There was a problem with the API call:', error);
      toast.error('Failed To Submit. Try Again');

    }
    setLoading(false) ;
  };
 

  return (
    <div className="pt-16 bg-stone-100">
     
      <div className="lg:mx-96 ">
      <div className={`${isLoading==false ? "hidden" : "visible"}  z-10 absolute top-1/2 left-1/3`} ><Spinner/></div>

        <div className="bg-black text-white  px-6 py-3  border-white border-b-2">
          <h2 className="text-lg">आमच्याशी संपर्क साधा</h2>
          <h6 className="text-xs">
           
          </h6>
        </div>
        <form onSubmit={handleSubmit} action="">
        <div className=" p-2 px-6">
          <div className="my-1">
            <label className="me-2 text-sm font-semibold" htmlFor="name">
              आपले नाव  
            </label>
            <br />
            <input
              className="p-1 w-full mt-1 border border-gray-400 rounded"
              id="name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="my-3">
            <label className="me-2 text-sm fontb" htmlFor="contact">
              मोबाईल नंबर 
            </label>
            <br />
            <input
              className="p-1 w-full mt-1  border-gray-400 border rounded"
              id="contact"
              type="Number"
              name="contact"
              value={formData.contact}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="my-3">
            <label className="me-2 text-sm fontb" htmlFor="email">
            ईमेल <p className="text-xs inline">( पर्यायी )</p> 
            </label>
            <br />
            <input
              className="p-1 w-full mt-1 border-gray-400 border rounded"
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="my-3">
            <label className="me-2 text-sm fontb" htmlFor="message">
              तुमचा संदेश प्रविष्ट करा 
            </label>

            <textarea
              className="p-1 w-full mt-1  border-gray-400 border rounded"
              name="message"
              id="message"
              onChange={handleInputChange}
              value={formData.message}
              rows="5"
              required
            ></textarea>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="bg-black text-white  p-1 px-10 rounded active:scale-90 ease-in-out transition"
            >
              संदेश पाठवा
            </button>
          </div>
        </div>
      
        </form>
        <h2 className="bg-black text-white px-6 py-3 text-lg">
          संपर्क माहिती
        </h2>
        <div className="px-6 py-4  fontb text-base">
        ईमेल :- <a className="text-blue-500 underline underline-offset-2 " href="mailto:livebailgada@gmail.com" > livebailgada@gmail.com</a>
        </div>
      </div>
    </div>
  );
}
