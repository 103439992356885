import React from 'react'
import logo from "../Images/logo2.png"

export default function Preloader() {
  return (
    <div className='h-screen preloader '>
        <img className='w-1/3 mx-auto' src={logo} alt="" />
        <h1 className='text-3xl font-extrabold' >Bailgada</h1>
        <h1 className='text-3xl font-extrabold' >Live</h1>
        <h1 className='text-orange-600 mt-4 text-3xl font-extrabold ac' >नाद एकच </h1>
        <h1 className='text-orange-600 text-5xl font-extrabold border-b-2 ac pt-2  border-blue-950'>बैलगाडा शर्यत</h1>
    </div>
  )
}
