import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoIosAddCircle } from "react-icons/io";
import ReactGA from "react-ga4";
import bg1 from "../Images/bg1.jpg";
import MaidanCard from "../Components/MaidanCard";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function Home(props) {
  const narr = props.arr;
  const narr1 = props.arr1.slice(0, 8);
  const narr2 = props.arr2.slice(0, 8);

  const { mname, mid } = props;
  const news = props.news;
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Bailgada Live - नाद एकच बैलगाडा शर्यत | बैलगाडा Live";
    ReactGA.send({
      hitType: "pageview",
      page: "/home",
      title: "Home View Title",
    });
    // window.scrollTo(0, 0);
  }, []);

  const handle = (name, id) => {
    window.scrollTo(0, 0);
    const formattedName = name.replace(/\s+/g, "-");
    console.log("name " + formattedName);
    ReactGA.event({
      category: name,
      action: "Maidan Click On Home PAge",
      label: "Home Page Maidan",
      value: id,
    });
    navigate(`/maidan/${formattedName}/${id}`);
  };

  const handle2 = (name, id) => {
    const formattedName = name.replace(/\s+/g, "-");
    console.log("name " + formattedName);
    ReactGA.event({
      category: name,
      action: "Bail Click On Home PAge",
      label: "Home Page Bails",
      value: id,
    });
    navigate(`/bail/${formattedName}/${id}`);
  };

  return (
    <div
      className="pt-16 lg:pt-0 bg-cover bg-fixed bg-center"
      style={{ backgroundImage: `url(${bg1})` }}
    >
      <div className="bg-stone-100 bg-opacity-100  ">
        <div className="lg:px-96 ">
          <div className="flex text-center  pt-2 lg:pt-5 pb-1.5 bg-stone-100 bg-opacity-70 border-b  border-slate-300 lg:border-0">
            <div className="flex  overflow-x-auto whitespace-nowrap bulls ">
              {narr.map((ele, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      handle2(ele.name, ele._id);
                    }}
                  >
                    <div className="rounded-full w-16 mx-1.5 ">
                      <img
                        className="border border-neutral-400   bg-white rounded-full "
                        src={ele.image[0]}
                        alt=""
                      />
                    </div>
                    <div>
                      <h3 className=" font-semibold text-sm">{ele.name}</h3>
                    </div>
                  </div>
                );
              })}
            </div>
            <Link to={"/bailinput"} className="text-7xl active:scale-90">
              <IoIosAddCircle className="pb-1" />
            </Link>
          </div>
          {props.arr3.length > 0 && (
            <div className="rounded mt-4 h-full my-3 ">
              <Swiper
                slidesPerView={"auto"}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                centeredSlides={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
                spaceBetween={0}
              >
                {props.arr3.map((ele) => {
                  return (
                    <SwiperSlide className=" ">
                      <div className=" w-5/6  mx-auto  mb-7 ">
                        <div
                          className="  w-11/12  mx-auto bg-cover bg-top rounded-xl relative my-3 border-2    border-amber-300 "
                          style={{
                            backgroundImage: `url(${ele.image[0]})`,
                            height: "60vh",
                          }}
                        >
                          <div className="h-full w-full rounded bg-gradient-to-t from-black    via-transparent to-transparent">
                            <div className="flex flex-row absolute bottom-0 w-full p-3 justify-around gap-4 items-center  mb-4">
                              {ele.videos.length > 0 && (
                                <h1 className="text-lg grow  text-center  rounded-lg  bg-white  active:scale-90 transition-all ">
                                  <a href={ele.videos[0].link}>लाईव्ह पाहा </a>
                                </h1>
                              )}

                              <h1
                                onClick={() => {
                                  handle(ele.name, ele._id);
                                }}
                                className="font  font- text-lg grow  text-center   rounded-lg bg-white  active:scale-90 transition-all "
                              >
                                अजून वाचा
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}

                {news.map((ele) => {
                  return (
                    <SwiperSlide>
                      <div
                        style={{
                          height: "60vh",
                        }}
                        className="mx-auto bg-gradient-to-tr from-orange-500 via-white to-pink-500  mb-7 w-5/6  flex justify-center items-center  text-black  rounded-3xl border-2    border-amber-300 text-4xl font-bold text-center px-4 "
                      >
                        <h1 className="leading-relaxed">{ele.title}</h1>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          )}

          {mname && (
            <div
              onClick={() => {
                handle(mname, mid);
              }}
              className="p-2 py-4 bgpink shadow-lg	shadow-pink-400 rounded-xl my-1 mx-2 border mt-3 text-white  border-black "
            >
              <h1 className="text-4xl text-center leading-snug  ">
                {mname} मैदानाचा निकाल निकाल निकाल
              </h1>
            </div>
          )}

          <div className="bg-stone-200 my-3">
            <Link to="/maidaninput">
              <div className="m-2  mt-4 ">
                <button className="bg-white w-full py-1 shadow-lg 	shadow-black/40 border-neutral-600 font-custom2  border rounded-lg  text-center active:scale-90 transition-all cursor-pointer ">
                  मैदानाची नोंद करा
                </button>
              </div>
            </Link>
          </div>
          <div className="px-0.5">
            <div className="bg-black text-white mt-2 p-2 rounded">
              <h1 className="inline ">आगामी मैदाने</h1>
              <Link
                to="/maidan"
                className="bg-white text-black rounded text-xs p-1 float-right active:scale-75 transition-all"
              >
                आणखी पहा
              </Link>
            </div>
            <div className="flex flex-wrap justify-between rounded p-1 text-center z-0 mx-4 lg:mx-0">
              {narr2.map((ele, index) => {
                const date = new Date(ele.date);
                const formattedDate = date
                  .toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                  .split("/")
                  .join("-");

                return (
                  <MaidanCard
                    name={ele.name}
                    _id={ele._id}
                    image={ele.image[0]}
                    handle={handle}
                    fee={ele.fee}
                    village={ele.address.village}
                    taluka={ele.address.taluka}
                  />
                  // <div
                  //   key={index}
                  //   onClick={() => {
                  //     handle(ele.name, ele._id);
                  //   }}
                  //   className="bg-white width1 mt-3 h-56 w-full bg-cover bg-top  border-neutral-800 border rounded relative "
                  //   style={{ backgroundImage: `url(${ele.image[0]})` }}
                  // >
                  //   <div className="h-full w-full bg-gradient-to-t from-orange-500 from-20% via-transparent via-45% to-transparent to-45%">
                  //     {/* <img
                  //   className="w-full h-56 object-cover lg:h-56 object-top rounded  "
                  //   src={ele.image[0]}
                  //   alt=""
                  // /> */}
                  //     {ele.fee!=-1 && <div className="absolute bg-black text-white -right-1 -top-1 text-xs rounded-3xl p-1">
                  //       {ele.fee}
                  //     </div>}
                  //     <div className=" border-neutral-600 border-t-2 pb-1 absolute bottom-0 w-2/3  translate-x-1/4  text-white rounded-lg   ">
                  //       {/* <h1 className="mt-1 font-medium ">{ele.name}</h1> */}
                  //       <h3 className="mt-1 font-bold bg-white text-black rounded-xl ">
                  //         आता पहा
                  //       </h3>
                  //       <p className="text-xs my-1 ">{ele.address.village}, {ele.address.taluka}</p>
                  //     </div>
                  //   </div>
                  // </div>
                );
              })}
            </div>
          </div>
          <div className="px-0.5">
            <div className="bg-black text-white mt-2 p-2 rounded">
              <h1 className="inline">मागील मैदाने </h1>
              <Link
                to="/maidan"
                className="bg-white text-black rounded text-xs p-1 float-right active:scale-75 transition-all"
              >
                आणखी पहा
              </Link>
            </div>
            <div className="flex flex-wrap    justify-between  rounded p-1 text-center z-0 mx-4 lg:mx-0">
              {narr1.map((ele, index) => {
                const date = new Date(ele.date);
                const formattedDate = date
                  .toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                  .split("/")
                  .join("/");

                return (
                  <MaidanCard
                    name={ele.name}
                    _id={ele._id}
                    image={ele.image[0]}
                    handle={handle}
                    fee={ele.fee}
                    village={ele.address.village}
                    taluka={ele.address.taluka}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* <div
key={index}
onClick={() => {
  handle(ele.name, ele._id);
}}
className="bg-white width1  lg:h-72 mt-3     border-neutral-800 border rounded "
>
<img
  className="w-full h-24  lg:h-56 object-cover object-top rounded  "
  src={ele.image[0]}
  alt=""
/>
<div className=" border-neutral-600 border-t-2 mb-1  ">
  <h1 className="mt-1 font-medium">{ele.name}</h1>
  <p className="text-xs ">दिनांक : {formattedDate}</p>
</div>
</div> */
}

{
  /* <h3 className="font-bold bg-black p-1 text-white px-8 rounded text-start mb-2 py-1.5">
              आजची मैदान{" "}
            </h3> */
}
